<template>
  <div
    :id="id"
    class="uk-padding uk-background-cover uk-background-default"
    :style="`background-image: url(${vectorPatternLight})`"
  >
    <div
      class="uk-width-1-2@l uk-width-2-3@m uk-width-3-5@xl uk-padding uk-text-center uk-margin-auto-left uk-margin-auto-right uk-background-default"
    >
      <h2
        class="uk-text-bold uk-margin-small-bottom uk-width-2-3@l uk-width-2-3@m uk-margin-auto-left uk-margin-auto-right"
      >
        {{ title }}
      </h2>
      <p
        class="uk-text-bold uk-margin-remove-top uk-width-2-3@l uk-width-2-3@m uk-margin-auto-left uk-margin-auto-right"
      >
        <slot />
      </p>

      <contact-form
        :csrf="csrf"
        :form-id="formId"
        :route="route"
        :submit-text="submitText"
        :message-placeholder="messagePlaceholder"
        classes="uk-width-2-3@l uk-width-2-3@m uk-margin-auto-left uk-margin-auto-right uk-margin-medium-top"
      />
    </div>
  </div>
</template>

<script>
import vectorPatternLight from '@/web/img/brand/vector-pattern-light.png'

import ContactForm from './ContactForm.vue'

export default {
  name: 'ContactFormWrapper',
  components: { ContactForm },
  props: {
    route: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: 'getYourQuote',
    },
    formId: {
      type: String,
      default: 'get_your_quote',
    },
    title: {
      type: String,
      default: 'Get Your Quote Now',
    },
    rows: {
      type: Number,
      default: undefined,
    },
    messagePlaceholder: {
      type: String,
      default: undefined,
    },
    submitText: {
      type: String,
      default: undefined,
    },
    csrf: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      vectorPatternLight,
    }
  },
}
</script>
